import { Validators, FormForType, formFor, Validator } from "fw-model";

import { ApplicationSource, ApplicationSourceJavascriptTransformation } from "models/application-source";

import { eventNotificationFormCreator } from "forms/scheduled-export";
import { isValidJavascript } from "./validators";
import { Override } from "helpers/ts-helpers";
import { EventNotification } from "models/scheduled-export";
const { wrap } = Validators;

export type ApplicationSourceFormType = Override<FormForType<ApplicationSource>, {
  JavascriptTransformation: FormForType<ApplicationSourceJavascriptTransformation>
  Notifications: FormForType<EventNotification>[]
}>;

export class UpdateApplicationSourceAction {
  constructor(public form: ApplicationSourceFormType) {}
}

export class AddApplicationSourceAction {
  constructor(public form: ApplicationSourceFormType) {}
}


const applicationSourceJavascriptTransformationFormCreator = formFor(ApplicationSourceJavascriptTransformation, s => {
  s.field(u => u.IncludeFunctionIds, "Include Functions");
  s.requiredField(u => u.Function, "function (data, filename)", wrap(isValidJavascript));
});

export const applicationSourceFormCreator = formFor(ApplicationSource, s => {
  s.requiredField(u => u.Name, "Name");
  s.requiredField(a => a.FileSourceId, "File Source");
  s.field(a => a.ProgramId, "Program");
  s.field(a => a.Path, "Path");
  s.field(a => a.Active, "Active");

  s.requiredField(a => a.FileCountType, "Expected File Record Count");
  s.formArray(u => u.Notifications, "Notifications", eventNotificationFormCreator);

  s.field(a => a.FileMask, "File Mask");
  s.form(a => a.JavascriptTransformation, "Transform", applicationSourceJavascriptTransformationFormCreator);
}) as unknown as (thing: ApplicationSource) => ApplicationSourceFormType;
