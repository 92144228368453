import { inject } from "fw";
import { Store, handle, dispatch } from "fw-state";
import { LocalStorageCache, MemoryCache } from "caching";

import {
  StartAction,
  LogoutAction,
  WatchTaskAction,
  TaskFinishedAction,
  RefreshDashboardDataAction,
  TaskUpdatedAction,
  EnsureTaskRequestAction,
  PortalInvitationsRefreshListAction,
} from "./actions";
import { TaskRequest, TaskRequestStatusTypeCode } from "models/task-request";
import { TaskWatcher, isTerminal } from "service/task-watcher";
import { Notification } from "service/notification";
import { TaskRequestRepository } from "network/task-request-repository";
import { wait } from "wait";
import { UserInvitationsRefreshAction } from "./manage-user-invites";

interface Stats {
  total: number;
  completed: number;
  errored: number;
}

interface TaskRequestStoreShape {
  organizationId: string;
  userId: string;

  runningTasks: { task: TaskRequest; name: string }[];
  runningStats: Stats;
  finishedTasks: TaskRequest[];
}

@inject
export class TaskRequestStore extends Store<TaskRequestStoreShape> {
  constructor(
    private watcher: TaskWatcher,
    private cache: LocalStorageCache,
    private memoryCache: MemoryCache,
    private notification: Notification,
    private taskRequestRepo: TaskRequestRepository
  ) {
    super();
  }

  defaultState() {
    return {
      organizationId: null,
      userId: null,

      runningTasks: [],
      finishedTasks: [],
      runningStats: {
        total: 0,
        completed: 0,
        errored: 0,
      },
    };
  }

  @handle(StartAction)
  private async handleStartAction(action: StartAction) {
    const { Me, Organization } = action.context;

    this.setState((state) => ({
      ...state,
      organizationId: Organization.Id,
      userId: Me.Id,
    }));

    this.watcher.clearAll();

    // maybe we have a list of currently running tasks that we need to watch?
    await this.restoreState();
  }

  @handle(LogoutAction)
  private handleLogoutAction() {
    this.watcher.clearAll();
    this.setState((s) => this.defaultState());
    this.saveState();
  }

  private getStats(tasks: TaskRequest[]): Stats {
    const stats: Stats = {
      completed: 0,
      errored: 0,
      total: 0,
    };

    tasks.forEach((t) => {
      stats.completed += t.CompletedActions;
      stats.errored += t.ErroredActions;
      stats.total += t.TotalActions;
    });

    return stats;
  }

  @handle(EnsureTaskRequestAction)
  private async handleEnsureTaskRequestAction(action: EnsureTaskRequestAction) {
    try {
      const tasks = await this.taskRequestRepo.getIds(action.taskRequestIds);
      for (const task of tasks) {
        if (isTerminal(task)) {
          this.setState((state) => ({
            ...state,
            finishedTasks: [...state.finishedTasks, task],
          }));
          await dispatch(new TaskFinishedAction(task));
        } else {
          // think about what we want to do with the name...
          await dispatch(
            new WatchTaskAction(task, action.friendlyName || task.Type)
          );
        }
      }
    } catch {
      // Save the state, this would clear any invalid bad ids that we tried to load on startup.
      this.saveState();
    }
  }

  @handle(WatchTaskAction)
  private handleWatchTaskAction(action: WatchTaskAction) {
    if (this.watcher.isWatching(action.taskRequest)) return;

    this.watcher.watch(action.taskRequest);

    const runningTasks = [
      ...this.state.runningTasks,
      { task: action.taskRequest, name: action.friendlyName },
    ];

    this.setState((state) => ({
      ...state,
      runningTasks,
      runningStats: this.getStats(runningTasks.map((rt) => rt.task)),
    }));
    this.saveState();
  }

  @handle(TaskUpdatedAction)
  private handleTaskUpdatedAction(action: TaskUpdatedAction) {
    const existingRunningTask = this.state.runningTasks.find(
      (t) => t.task.Id == action.taskRequest.Id
    );
    if (existingRunningTask == null) {
      return;
    }

    Object.assign(existingRunningTask.task, action.taskRequest);
    this.setState((state) => ({
      ...state,
      runningStats: this.getStats(state.runningTasks.map((rt) => rt.task)),
    }));
  }

  @handle(TaskFinishedAction)
  private async handleTaskFinishedAction(action: TaskFinishedAction) {
    const runningTasks = this.state.runningTasks.slice(0);
    const finishedTasks = [
      ...this.state.finishedTasks.slice(0),
      action.taskRequest,
    ];

    const watchingTask = runningTasks.find(
      (t) => t.task.Id == action.taskRequest.Id
    );

    if (watchingTask != null) {
      if (runningTasks.length === 1) {
        // for last task in progress bar and 1 second delay to handle the potential Elastic latency
        await wait(1000);
      }
      const idx = runningTasks.indexOf(watchingTask);
      runningTasks.splice(idx, 1);
    }

    this.setState((state) => ({
      ...state,
      runningTasks,
      finishedTasks,
      runningStats: this.getStats(runningTasks.map((rt) => rt.task)),
    }));
    this.saveState();

    if (action.taskRequest.Status == TaskRequestStatusTypeCode.Error) {
      let errorMessage: string | string[] = null;
      switch (action.taskRequest.Type) {
        case "DecisionLetter": {
          const errorsHash = JSON.parse(
            action.taskRequest.Data["errors"] || "{}"
          );
          errorMessage = Object.values<string>(errorsHash);
          break;
        }
      }

      this.notification.error(errorMessage || '"A Task failed..."');
      return;
    }

    switch (action.taskRequest.Type) {
      case "Reindex":
      case "RunAssignmentRules":
        this.memoryCache.clearAll();
        await dispatch(new RefreshDashboardDataAction());
        break;

      case "CollaborationAssignment":
        this.memoryCache.clearAll();
        await dispatch(new RefreshDashboardDataAction());
        break;

      case "SendUserInvitations":
        await wait(500);
        await dispatch(new PortalInvitationsRefreshListAction());
        await dispatch(new UserInvitationsRefreshAction());
        break;

        case "SendEmails":
        // The SendEmails task can be partially successful, so check if we need to display any errors.
        const errors = JSON.parse(action.taskRequest.Data["errors"] || "[]");
        if (errors.length > 0) {
          this.notification.error(
              errors,
              `Email Delivery Status: ${action.taskRequest.CompletedActions} delivered, ${action.taskRequest.ErroredActions} errors`
          );
        }
        break;
    }

    if (watchingTask != null) {
      this.notification.notify(`${watchingTask.name} Finished`);
    }
  }

  private saveState() {
    this.cache.set<string[]>(
      `${this.state.organizationId}:${this.state.userId}:task-requests`,
      this.state.runningTasks.map((m) => m.task.Id),
      60 * 60 * 1000
    );
  }

  private async restoreState() {
    if (!this.state.userId) {
      return;
    }

    const savedTaskRequestIds =
      this.cache.get<string[]>(
        `${this.state.organizationId}:${this.state.userId}:task-requests`
      ) || [];
    if (savedTaskRequestIds.length === 0) {
      return;
    }

    await dispatch(new EnsureTaskRequestAction(savedTaskRequestIds));
  }
}
