import { FormForType, formFor, Validators } from "fw-model";
const { isInteger, inRange, isUrl, required, wrap } = Validators;
import moment from "moment";
import { eachRequired, isEachValidDate, isValidDate, noNewLines } from "./validators";

import {
  CalendarEvent,
  CalendarEventActivityPoints,
  CalendarEventAddress,
  CalendarEventExternalWebsite,
  CalendarEventRegistrationData,
  CalendarEventRegistrationLimits,
  CalendarEventRegistrationSchedule,
  CalendarEventRegistrationSettings,
  CalendarEventSchedule,
  CalendarEventScheduleInterval,
  CalendarEventSeries,
  CalendarEventTimeSlot,
  RegistrantContactData,
  TimeOfDay,
  UpdateCalendarEventModel
} from "models/calendar-event";
import { ImageInfo } from "models/image";
import { RefreshServerTime, UtilityStore } from "state/utilities";
import { dispatch } from "fw-state";

export const calendarEventActivityPointsFormCreator = formFor(CalendarEventActivityPoints, c => {
  c.field(f => f.attend, "Points for Attending");
  c.field(f => f.register, "Points for Registering");
  c.field(f => f.unregister, "Points for Un-registering");
});

export const calendarEventRegistrationDataFormCreator = formFor(CalendarEventRegistrationData, c => {
  c.field(f => f.details, "Registration (Next Step) Instructions");
  c.field(f => f.is_self_checkin_allowed, "Self-checkin");
  c.field(f => f.start_date, "Registration Start Date");
  c.field(f => f.end_date, "Registration End Date");
});

export const calendarEventRegistrationLimitsFormCreator = formFor(CalendarEventRegistrationLimits, c => {
  c.requiredField(f => f.count, "Maximum capacity", wrap(isInteger, inRange(0, 10000)));
  c.requiredField(f => f.guest_limit, "Guest count", wrap(isInteger, inRange(0, 10000)));
  c.requiredField(f => f.type, "Limit Type");
});

export type FormForTimeOfDay = FormForType<TimeOfDay>;
export const timeOfDayFormCreator = formFor(TimeOfDay, c => {
  c.field(f => f.hour, "Hour");
  c.field(f => f.minute, "Minute");
});

export const calendarEventRegistrationScheduleActivationFormCreator = formFor(CalendarEventRegistrationSchedule, c => {
  c.requiredField(f => f.frequency_type, "Frequency Interval");
  c.field(f => f.countdown, "Registration Start Countdown", v => v.if(g => g.frequency_type === CalendarEventScheduleInterval.DaysBefore, required, isInteger, inRange(0, 100)));
  c.field(f => f.date, "Registration Start Date", v => v.if(g => g.frequency_type === CalendarEventScheduleInterval.CustomDate, required, isValidDate));
  c.form(f => f.time, "Time", timeOfDayFormCreator);
});

export const calendarEventRegistrationScheduleClosedFormCreator = formFor(CalendarEventRegistrationSchedule, c => {
  c.requiredField(f => f.frequency_type, "Frequency Interval");
  c.field(f => f.countdown, "Registration End Countdown", v => v.if(g => g.frequency_type === CalendarEventScheduleInterval.DaysBefore,  required, isInteger, inRange(0, 100)));
  c.field(f => f.date, "Registration End Date", v => v.if(g => g.frequency_type === CalendarEventScheduleInterval.CustomDate, required, isValidDate));
  c.form(f => f.time, "Time", timeOfDayFormCreator);
});

export const calendarEventRegistrationSettingsFormCreator = formFor(CalendarEventRegistrationSettings, c => {
  c.requiredField(f => f.inquiry_form_id, "Inquiry form");
  c.field(f => f.details, "Registration (Next Step) Instructions");
  c.field(f => f.is_self_checkin_allowed, "Self-checkin");
  c.form(f => f.limits, "Limits", calendarEventRegistrationLimitsFormCreator);
  c.form(f => f.active_options, "Activation Options", calendarEventRegistrationScheduleActivationFormCreator);
  c.form(f => f.closed_options, "Closed Options", calendarEventRegistrationScheduleClosedFormCreator);
})

export const calendarEventImagesFormCreator = formFor(ImageInfo, c => {
  c.field(f => f.id, "Id");
  c.field(f => f.type, "Type");
  c.field(f => f.path, "Path");
  c.field(f => f.url, "Url");
})

export type FormForCalendarEventTimeSlot = FormForType<CalendarEventTimeSlot>;
export const calendarEventTimeSlotFormCreator = formFor(CalendarEventTimeSlot, c => {
  c.form(f => f.from, "From", timeOfDayFormCreator);
  c.form(f => f.to, "To", timeOfDayFormCreator);
});

export type FormForCalendarEventSchedule = FormForType<CalendarEventSchedule>;
export const calendarEventScheduleFormCreator = formFor(CalendarEventSchedule, c => {
  c.requiredField(f => f.schedule_type, "Schedule Type");
  c.field(f => f.is_all_day, "All Day Event");
  c.field(f => f.start_date, "Series Start Date");
  c.field(f => f.end_date, "Series End Date", v => v.if(g => g.schedule_type === "weekly" || g.schedule_type === "daily", required));
  c.field(f => f.dates, "Specified Dates", v => v.if(g => g.schedule_type == "custom-dates", eachRequired, isEachValidDate));
  c.field(f => f.week_days, "Week Days");
  c.formArray(f => f.time_slots, "Time Slots", calendarEventTimeSlotFormCreator);
});

export const calendarEventAddressFormCreator = formFor(CalendarEventAddress, a => {
  a.field(f => f.address1, "Address 1");
  a.field(f => f.address2, "Address 2");
  a.field(f => f.city, "City");
  a.field(f => f.state, "State");
  a.field(f => f.postal_code, "Postal Code");
})

export const calendarEventExternalWebsiteFormCreator = formFor(CalendarEventExternalWebsite, s => {
  s.field(f => f.name, "Website Name");
  s.field(f => f.url, "Website Url (External)", wrap(isUrl({ allowedProtocols: ["http", "https"], requireProtocol: true, allowPath: true })));
});

export type FormForCalendarEvent = FormForType<CalendarEvent>;
export const calendarEventFormCreator = formFor(CalendarEvent, c => {
  c.requiredField(f => f.id, "Id");
  c.requiredField(f => f.calendar_event_series_id, "Series Id");
  c.requiredField(f => f.date, "Date");
  c.requiredField(f => f.is_all_day, "All Day Event");
  c.requiredField(f => f.name, "Name");
  c.requiredField(f => f.timezone, "Event Timezone");
  c.requiredField(f => f.instance_key, "Instance Key");
  c.field(f => f.instance_type, "Instance Type", v => v.if(g => g.schedule_type === "weekly", required));
  c.requiredField(f => f.schedule_recurrence_key, "Schedule Key");
  c.requiredField(f => f.schedule_type, "Schedule Type");
  c.requiredField(f => f.status, "Status");
  c.field(f => f.display_name, "Display Name");
  c.field(f => f.location, "Location");
  c.field(f => f.description, "Description", wrap(noNewLines));
  c.field(f => f.details, "Additional Details", wrap(noNewLines));
  c.field(f => f.calendar_event_type_id, "Event Type");
  c.field(f => f.tags, "Tags");
  c.field(f => f.is_featured, "Featured Event");
  c.field(f => f.is_private, "Is Private");
  c.form(f => f.time_slot, "Time Slot", calendarEventTimeSlotFormCreator);
  c.form(f => f.address, "Address", calendarEventAddressFormCreator);
  c.form(f => f.external_website, "Url", calendarEventExternalWebsiteFormCreator);
});

export type FormForUpdateCalendarEventModel = FormForType<UpdateCalendarEventModel>;
export const updateCalendarEventFormCreator = formFor(UpdateCalendarEventModel, c => {
  c.requiredField(f => f.id, "Id");
  c.requiredField(f => f.name, "Name");
  c.requiredField(f => f.calendar_event_series_id, "Series Id");
  c.requiredField(f => f.instance_key, "Instance Key");
  c.requiredField(f => f.timezone, "Event Timezone");
  c.requiredField(f => f.date, "Date");
  c.requiredField(f => f.is_all_day, "All Day Event");
  c.requiredField(f => f.status, "Status");
  c.field(f => f.description, "Description", wrap(noNewLines));
  c.field(f => f.details, "Additional Details", wrap(noNewLines));
  c.field(f => f.location, "Location");
  c.form(f => f.time_slot, "Time Slot", calendarEventTimeSlotFormCreator);
  c.form(f => f.address, "Address", calendarEventAddressFormCreator);
  c.form(f => f.limits, "Limits", calendarEventRegistrationLimitsFormCreator);
  c.field(f => f.display_name, "Display Name");
  c.requiredField(f => f.calendar_event_type_id, "Event Type");
  c.field(f => f.tags, "Tags");
  c.form(f => f.external_website, "Url", calendarEventExternalWebsiteFormCreator);
  c.requiredField(f => f.name, "Name");
  c.formArray(f => f.images, "Images", calendarEventImagesFormCreator);
  // c.field(f => f.inquiry_form_key, "Inquiry Form");                    // awaiting spike on how to handle this
  c.field(f => f.contact_segment_id, "Invitation Segment");
  c.field(f => f.registration_details, "Registration Instructions");
  c.field(f => f.registration_start_date, "Registration Start Date");
  c.field(f => f.registration_end_date, "Registration End Date");
  c.field(f => f.is_self_checkin_allowed, "Self-checkin");
  c.form(f => f.registration_data, "Registration Data", calendarEventRegistrationDataFormCreator);
});

export type FormForCalendarEventSeries = FormForType<CalendarEventSeries>;
export const calendarEventSeriesDraftFormCreator = formFor(CalendarEventSeries, c => {
  c.requiredField(f => f.name, "Name");
  c.requiredField(f => f.timezone, "Event Timezone");
  c.requiredField(f => f.calendar_event_type_id, "Event Type");
  c.requiredField(f => f.status, "Status");
  c.field(f => f.display_name, "Display Name");
  c.field(f => f.location, "Location");
  c.field(f => f.description, "Description", wrap(noNewLines));
  c.field(f => f.details, "Additional Details", wrap(noNewLines));
  c.field(f => f.tags, "Tags");
  c.field(f => f.inquiry_form_key, "Inquiry Form");
  c.field(f => f.contact_type, "Contact Type");
  c.field(f => f.contact_segment_id, "Segment");
  c.field(f => f.is_featured, "Featured Event");
  c.field(f => f.is_private, "Is Private");
  c.form(f => f.address, "Address", calendarEventAddressFormCreator);
  c.form(f => f.external_website, "Url", calendarEventExternalWebsiteFormCreator);
  c.form(f => f.schedule, "Series Schedule", calendarEventScheduleFormCreator);
  c.form(f => f.activity_points, "Points Assigned to Activities", calendarEventActivityPointsFormCreator);
  c.form(f => f.registration_settings, "Limits", calendarEventRegistrationSettingsFormCreator);
  c.formArray(f => f.images, "Images", calendarEventImagesFormCreator);
});

export const calendarEventSeriesFormCreator = formFor(CalendarEventSeries, c => {
  c.requiredField(f => f.name, "Name");
  c.requiredField(f => f.timezone, "Event Timezone");
  c.requiredField(f => f.calendar_event_type_id, "Event Type");
  c.requiredField(f => f.contact_type, "Contact Type");
  c.requiredField(f => f.inquiry_form_key, "Inquiry Form");
  c.requiredField(f => f.status, "Status");
  c.field(f => f.display_name, "Display Name");
  c.field(f => f.location, "Location");
  c.field(f => f.description, "Description", wrap(noNewLines));
  c.field(f => f.details, "Additional Details", wrap(noNewLines));
  c.field(f => f.tags, "Tags");
  c.field(f => f.contact_segment_id, "Segment");
  c.field(f => f.is_featured, "Featured Event");
  c.form(f => f.address, "Address", calendarEventAddressFormCreator);
  c.form(f => f.external_website, "Url", calendarEventExternalWebsiteFormCreator);
  c.form(f => f.schedule, "Series Schedule", calendarEventScheduleFormCreator);
  c.form(f => f.activity_points, "Points Assigned to Activities", calendarEventActivityPointsFormCreator);
  c.form(f => f.registration_settings, "Limits", calendarEventRegistrationSettingsFormCreator);
});

export type FormForCalendarEventRegistrantData = FormForType<RegistrantContactData>;
export const calendarEventRegistrantData = formFor(RegistrantContactData, c => {
  c.requiredField(f => f.contact_type, "Contact type");
  c.requiredField(f => f.first_name, "First Name");
  c.requiredField(f => f.last_name, "Last Name");
  c.requiredField(f => f.email_address, "Email");
  c.requiredField(f => f.attendees, "Number of Attendees");
  c.requiredField(f => f.type, "I am A");
  c.field(f => f.details, "Additional Details");
})

export class CalendarEventFormHelper {
  private form: FormForCalendarEventSeries = null;
  constructor(form: FormForCalendarEventSeries, private utilStore: UtilityStore) {
    this.form = form;
    this.ensureScheduleOptions();
    dispatch(new RefreshServerTime());
  }

  public ensureScheduleOptions() {
    this.ensureSchedule();
    if (this.isCustomDates) {
      this.form.schedule.start_date = moment.tz(this.form.timezone).toISOString();
      this.form.schedule.end_date = null;
    }

    this.ensureWeekDays();
    this.ensureDates();
    this.ensureTimeSlots();
  }

  private ensureSchedule() {
    if (this.form.schedule?.schedule_type) return;

    if (!this.form.schedule) {
      this.form.schedule = calendarEventScheduleFormCreator(new CalendarEventSchedule())
    }

    this.form.schedule.schedule_type = "custom-dates";
    this.form.schedule.is_all_day = true;
  }

  public get scheduleType() {
    return this.form.schedule?.schedule_type;
  }
  public get isDaily() {
    return this.scheduleType === "daily";
  }
  public get isWeekly() {
    return this.scheduleType === "weekly";
  }
  public get isMulti() {
    return this.scheduleType === "multi-day";
  }
  public get isCustomDates() {
    return this.scheduleType === "custom-dates";
  }

  public ensureDates() {
    if (!this.isCustomDates) {
      this.form.schedule.dates = [];
      return;
    }

    if (this.form.schedule?.dates && this.form.schedule.dates.length > 0) {
      return;
    } else {
      this.form.schedule.dates = [];
    }
    this.addDefaultDate();
  }

  public addDefaultDate() {
    const date = moment(this.utilStore.state.serverTime)
      .tz(this.form.timezone)
      .startOf("day")
      .toISOString();

    this.form.schedule.dates.push(date);
  }

  public addSelectedDate(date, index) {
    const updatedDate = moment.tz(date, this.form.timezone).toISOString();
    this.form.schedule.dates[index] = updatedDate;
  }

  public ensureTimeSlots() {
    if (this.form.schedule.is_all_day) {
      this.form.schedule.time_slots = [];
      return;
    }

    if (this.form.schedule?.time_slots && this.form.schedule.time_slots.length > 0) {
      return;
    } else {
      this.form.schedule.time_slots = [];
    }
    this.addDefaultTimeSlot();
  }

  public addDefaultTimeSlot() {
    const timeSlot: FormForCalendarEventTimeSlot = calendarEventTimeSlotFormCreator({
      from: { hour: 0, minute: 0 },
      to: { hour: 0, minute: 0 }
    });
    this.form.schedule.time_slots.push(timeSlot);
  }

  public ensureWeekDays() {
    if (!this.isWeekly) {
      this.form.schedule.week_days = [];
      return;
    }

    if (this.form.schedule?.week_days && this.form.schedule.week_days.length > 0) {
      return;
    } else {
      this.form.schedule.week_days = [];
    }
  }

}
