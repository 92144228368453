import { inject, prop, Navigator } from "fw";
import { DialogService } from "fw-dialog";
import { CheckSave } from "./check-save";
import { Permissions } from "service/permission";
import { Confirm } from "service/confirm";

@inject
export class ActionHeader {
  constructor(
    private nav: Navigator,
    private dialogService: DialogService,
    private permService: Permissions,
    private confirmService: Confirm
  ) {}

  @prop(null) private pageTitle;
  @prop(null) public backText: string;
  @prop(null) private backUrl: string;
  @prop(null) private backUrlQueryParams: any;
  @prop(null) private perms;
  @prop(null) private onBack!: Function;
  @prop(null) private onSave!: Function;
  @prop(null) private onDiscard!: Function;
  @prop(null) private isDirty!: Function;
  @prop(false) public disabled!: boolean;
  @prop(null) private saveButtonText!: string;
  @prop(false) private hideSaveButton!: boolean;

  public get hasPermission() {
    return this.perms == null || this.permService.all(this.perms);
  }

  public saving: boolean = false;

  private async goBack() {
    if (this.onBack != null) {
      this.onBack();
    }
    if (this.backUrl != null) {
      if (!!this.backUrlQueryParams) {
        this.nav.navigate(this.backUrl, this.backUrlQueryParams);
      } else {
        this.nav.navigate(this.backUrl);
      }
    }
  }

  public async beforeBack() {
    if (this.isDirty != null && this.isDirty()) {
      if (!this.hasPermission) {
        this.goBack();
        return;
      }

      const result = await this.dialogService.open(
        CheckSave,
        {
          discard: this.onDiscard,
          save: () => this.save(true),
          showSave: this.onSave != null,
        },
        { cssClass: "confirmation-dialog" }
      );

      if (result.canceled) {
        return;
      }
    }

    this.goBack();
  }

  public async save(isBack: boolean = false, event: Event = null) {
    if (!this.hasPermission) {
      this.confirmService.systemMessage("You do not have the required permission to perform this action.");
      return;
    }

    if (this.onSave == null) {
      return;
    }

    const res = this.onSave(isBack, event);

    if (res instanceof Promise) {
      this.saving = true;
      let throwErr = null;

      try {
        await res;
      } catch (err) {
        throwErr = err;
      } finally {
        this.saving = false;
      }

      if (throwErr != null) throw throwErr;
    }
  }
}
