import { inject } from "fw";

import { getContrastingColor } from "helpers/accessibility";
import { PopoverController } from "service/popover";
import { FeatureFlagService } from "service/feature-flag";

export type OptionChoice<T> = { text: string; value: T; color?: string };

export type CategoryOptionChoice<T> = {
  category: string;
  options: OptionChoice<T>[];
  filteredOptions?: OptionChoice<T>[];
};

type OptionChoiceOrCatChoice<T> = OptionChoice<T> | CategoryOptionChoice<T>;

export interface Options {
  confirmText?: string;
  hideSearch?: boolean;
  options: OptionChoiceOrCatChoice<unknown>[];
  searchHint?: string;
  selectedOption?: OptionChoiceOrCatChoice<unknown>;
  title?: string;
  updateOptionFocus?: Function;
}

@inject
export class OptionChooserPopover {
  public isCategory: boolean = false;
  public options: OptionChoiceOrCatChoice<unknown>[] = [];
  public title: string = null;
  public confirmText: string = null;
  public selectedOption: OptionChoiceOrCatChoice<unknown> = null;
  public searchTerm: string = "";
  public searchHint: string = "Search";
  public hideSearch: boolean = false;
  public updateOptionFocus: Function = () => {};

  constructor(
    private ffs: FeatureFlagService,
    private controller: PopoverController<OptionChoiceOrCatChoice<unknown>>
  ) {}

  public activate(options: Options | OptionChoiceOrCatChoice<unknown>[]) {
    if (typeof options === "object" && "options" in options) {
      this.title = options.title;
      this.confirmText = options.confirmText;
      this.hideSearch = !!options.hideSearch;
      this.searchHint = options.searchHint || this.searchHint;
      this.options = options.options || [];
      this.selectedOption = options.selectedOption;
      if (options?.updateOptionFocus) {
        this.updateOptionFocus = options?.updateOptionFocus;
      }
      this.updateOptionFocus(this.makeOptionId(this.selectedOption));
    } else {
      this.options = options || [];
    }

    this.isCategory =
      this.options.length > 0 &&
      (<CategoryOptionChoice<unknown>>this.options[0]).category != null;
  }

  public makeOptionId(field: OptionChoiceOrCatChoice<unknown>): string {
    if (!field) {
      return;
    }

    let key = "text" in field ? field?.text : field?.category;
    if (key) {
      key = key.replace(/\.| /g, "");
    }
    return !!key ? `${key}-ocp` : null;
  }

  public search(opts: OptionChoice<unknown>[]): OptionChoice<unknown>[] {
    if (this.searchTerm.length) {
      return opts.filter((o) =>
        o.text
          ? o.text.toLowerCase().includes(this.searchTerm.toLowerCase())
          : false
      );
    } else {
      return opts;
    }
  }

  public categorySearch(category: CategoryOptionChoice<unknown>): boolean {
    if (this.searchTerm.length) {
      category.filteredOptions = category.options.filter((o) =>
        o.text
          ? o.text.toLowerCase().includes(this.searchTerm.toLowerCase())
          : false
      );
      return category.filteredOptions.length > 0;
    } else {
      category.filteredOptions = category.options;
      return true;
    }
  }

  public selectOption(option: OptionChoiceOrCatChoice<unknown>) {
    if (this.confirmText == null) {
      this.controller.ok(option);
    } else {
      this.selectedOption = option;
    }
    this.updateOptionFocus(this.makeOptionId(option));
  }

  public getContrastingColor(color) {
    return getContrastingColor(color);
  }

  public confirm() {
    if (this.selectedOption == null) {
      return;
    }
    this.controller.ok(this.selectedOption);
  }

  public get actionMenuAriaLabel(): string {
    return this.title ? `Popover action menu for ${this.title}` : "Popover action menu";
  }
}
