import { prop, ComponentEventBus, inject } from "fw";
import { dispatch } from "fw-state";
import {
  Completer,
  correctPopoverPosition,
  getTokenWord,
  replaceVariable,
  VariablePicked
} from "helpers/auto-complete";
import { CustomFieldType } from "models/contact-organization";
import { FunctionType } from "models/function";
import { PopoverService, PopoverCoordinator } from "service/popover";

import offset from "document-offset";
import { EditorPopover } from "./editor-popover";
import VDatePicker from "v-calendar";
import Vue from "vue";
import { CurrentOrganizationStore } from "state/current-organization";
import moment from "moment";
import { RefreshServerTime, UtilityStore } from "state/utilities";
import {
  accessibilityInstructions,
  navigationInstructions,
} from "./v-date-picker.constants";
import { unionBy } from "lodash-es";

let uniqueId = 0;

@inject
export class TextField {
  constructor(
    private componentEventBus: ComponentEventBus,
    private popover: PopoverService,
    private coordinator: PopoverCoordinator,
    private currentOrganizationStore: CurrentOrganizationStore,
    private utilStore: UtilityStore
  ) {
    this.timezone = this.currentOrganizationStore?.state?.organization?.Timezone || this.timezone;
  }

  @prop(null) public label!: string;
  @prop(false) public hideLabel!: boolean;
  @prop(false) public hideLock!: boolean;
  @prop("") public placeholder!: string;
  @prop(null) public value;
  @prop(null) public validation!: string;
  @prop(null) public type!:
    | "date"
    | "email"
    | "tel"
    | "text"
    | "password"
    | "number"
    | "url"
    | "time";
  @prop(null) public min!: number;
  @prop(null) public max!: number;
  @prop(null) public maxlength!: number;
  @prop(null) public showDatePicker!: boolean;
  @prop(false) public allowNullDate!: boolean;
  @prop(null) public minDate: Date;
  @prop(null) public maxDate: Date;
  @prop(null) public readonly!: boolean;
  @prop(null) public required: boolean;
  @prop(null) public setfocus;
  @prop(false) public disabled!: boolean;
  @prop(false) public floatingLabel!: boolean;
  @prop(null) public ariaLabel!: string;
  @prop(null) public helpText!: string;
  @prop(null) public autocomplete!: string;
  @prop(null) public completer!: Completer | Completer[];
  @prop(null) public contactType!: string;
  @prop(true) private useTimezone!: boolean;
  @prop("MM/DD/YYYY") private format!: string;
  @prop(null) public meta!: string;
  @prop(() => [
    CustomFieldType.address,
    CustomFieldType.date,
    CustomFieldType.dropdown,
    CustomFieldType.email,
    CustomFieldType.funnel,
    CustomFieldType.largestring,
    CustomFieldType.number,
    CustomFieldType.phone,
    CustomFieldType.string,
    CustomFieldType.user,
  ]) completionTypes: Array<CustomFieldType | FunctionType>;
  @prop(true) public handlebars: boolean;
  @prop(false) public showClear!: boolean;
  @prop(false) public trimText: boolean;

  private uniqueId = uniqueId++;

  private isFocused: boolean = false;
  private isDateFocused: boolean = false;
  private inputEl;
  private hinting: boolean = false;
  private timezone = "America/Chicago";
  private today: Date;

  public created() {
    Vue.use(VDatePicker);
  }

  public async attached() {
    await dispatch(new RefreshServerTime());
  }

  private get masks() {
    return {
      input: this.format,
    };
  };

  private get attributes() {
    this.today = this.useTimezone ?
      moment(this.utilStore.state.serverTime).tz(this.timezone).toDate() :
      new Date(this.utilStore.state.serverTime);

    return [
      {
        key: "today",
        highlight: {
          color: "blue",
          fillMode: "light",
        },
        dates: this.today,
      },
    ];
  }

  public get vDatePickerAccessibilityInstructions() {
    return `${navigationInstructions} ${accessibilityInstructions}`;
  }

  public get locked(): boolean {
    return this.disabled || (this.readonly && !this.showDatePicker);
  }

  public get inputAriaLabel(): string {
    let label = [];

    if (this.ariaLabel) {
      label.push(this.ariaLabel);
    }
    if (this.locked) {
      label.push('Field Locked.');
    }
    return label.join(" ");
  }

  public get dateAriaLabel(): string {
    const label = !!this.ariaLabel ? `${this.ariaLabel}: ` : '';
    return `${label}Calendar Opened. ${this.vDatePickerAccessibilityInstructions}`;
  }

  public makeDatePickerId() {
    return `date-picker-${this.uniqueId}`;
  }

  public makeId() {
    return `${this.labelAsId}-tf-${this.uniqueId}`;
  }

  public makeLabelId() {
    return `${this.labelAsId}-label-tf-${this.uniqueId}`;
  }

  public makeErrorId() {
    return `validation-error-${this.uniqueId}`;
  }

  public onFocus(e) {
    this.isFocused = true;
    this.componentEventBus.dispatch("focus", e);
  }

  public onBlur(e) {
    if (this.type !== "password") {
      this.onInput(this.value);
    }
    this.isFocused = false;
    this.componentEventBus.dispatch("blur", e);
  }

  public onDateFocus(e) {
    this.isDateFocused = true;
  }

  public onDateBlur(e) {
    this.isDateFocused = false;
  }

  public onInput(value) {
    // value might be a non-string type that doesn't have trim function (i.e. number)
    let updateValue = value;
    if (this.trimText && typeof value?.trim === 'function' && this.type !== "password") {
      updateValue = value?.trim();
    }

    this.componentEventBus.updateModel(value);
    this.componentEventBus.dispatch("update:value", updateValue);
  }

  public clear() {
    this.componentEventBus.dispatch("update:value", null);
    this.componentEventBus.dispatch("clear");
  }

  public get labelAsId(): string {
    return `${this.label ? this.label.replace(/\s/g, "") : ""}`;
  }

  public get numberValue(): number {
    return this.value;
  }

  public set numberValue(value: number) {
    this.onInput(value);
  }

  public get dateValue() {
    return this.value;
  }

  public set dateValue(value) {
    const selectedDate = value !== null || this.allowNullDate ? value : null;
    const date = selectedDate && moment(selectedDate).format(this.format);
    this.onInput(date);
    this.onChange();
  }

  public onChange() {
    this.componentEventBus.dispatch("change");
  }

  public onKeyEvent(event) {
    this.componentEventBus.dispatch("keypress", event);
  }

  public onKeyDown(e) {
    if (this.completer) {
      this.handleKeyEvent(e, "keyDown");
    }
  }

  public onKeyUp(e) {
    if (this.completer) {
      this.handleKeyEvent(e, "keyUp");
    }

    this.componentEventBus.dispatch("keyup");
  }

  public handleKeyEvent(e: KeyboardEvent, type: string) {
    const key = e.keyCode,
      enter = 13,
      up = 38,
      down = 40,
      tab = 9;
    const specialPressed =
      key == enter || key == up || key == down || key == tab;

    if (type === "keyDown" && this.hinting && specialPressed)
      e.preventDefault();
    else if (type === "keyUp" && (!this.hinting || !specialPressed)) {
      this.coordinator.closeAll();
      this.hinting = false;
      this.hintVariables();
    }
  }

  public onEnterClick() {
    this.inputEl.blur();
  }

  private async hintVariables() {
    if (!this.completer) return null;

    const tw = getTokenWord(
      this.inputEl.value,
      this.inputEl.selectionStart,
      this.inputEl.selectionEnd
    );
    if (!tw) return null;

    const completer = !Array.isArray(this.completer)
      ? [this.completer]
      : this.completer;
    const getCompletions = async (i) =>
      await i.getCompletions(
        tw.token,
        tw.word,
        this.completionTypes,
        this.contactType,
        this.handlebars
      );
    const variables = unionBy.apply(this, [
      ...(await Promise.all(completer.map(getCompletions))),
      "token",
    ]);
    if (!variables.length || !variables[0] || (variables.length === 1 && variables[0].token === tw.word)) return null;

    // make a marker where the popover should appear
    const textPosition = offset(this.inputEl);
    const textareaCaret = (await import("textarea-caret")) as any;
    const position = textareaCaret.default(
      this.inputEl,
      this.inputEl.selectionEnd
    );
    const marker = document.createElement("span");
    marker.style.top = `${textPosition.top + position.height + 10}px`;
    marker.style.left = `${textPosition.left + position.left + 10}px`;
    marker.style.position = "absolute";
    document.body.append(marker);

    const handleResize = () => {
      const textPosition = offset(this.inputEl);
      const position = textareaCaret.default(
        this.inputEl,
        this.inputEl.selectionEnd
      );
      marker.style.top = `${textPosition.top + position.height + 10}px`;
      marker.style.left = `${textPosition.left + position.left + 10}px`;

      correctPopoverPosition();
    }

    window.addEventListener("resize", handleResize);

    this.hinting = true;
    const data = { variables: variables };
    const variablePicked = await this.popover.open<VariablePicked>(
      EditorPopover,
      data,
      marker,
      false,
      true
    );
    window.removeEventListener("resize", handleResize);
    marker.remove();

    if (variablePicked.result) {
      const { variable } = variablePicked.result;
      const value = this.inputEl.value;
      const tokenWord = getTokenWord(
        value,
        this.inputEl.selectionStart,
        this.inputEl.selectionEnd
      );
      const { newValue, newPosition } = replaceVariable(
        variable,
        value,
        tokenWord
      );

      this.inputEl.focus();
      this.onInput(newValue);

      // put cursor at the end of the replaced text
      setTimeout(() => {
        this.inputEl.selectionEnd = newPosition;
        this.hinting = false;
        this.hintVariables();
      }, 1);
    }
  }
}
